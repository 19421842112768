import React, { ReactNode } from "react";
import { classify } from "@utils/classify";
import classNames from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  tag?: "button";
}

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  tag: "a";
}

type Tags = "button" | "a";
type Themes = "is-default" | "is-dark" | "is-primary" | "is-transparent";

type CommonProps = {
  children?: ReactNode;
  expanded?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  label?: string;
  shadow?: boolean;
  tag?: Tags;
  theme?: Themes;
};

type Props = CommonProps & (ButtonProps | LinkProps);

type BtnContentProps = {
  content?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
};

const AppBtnContent = ({ content, iconLeft, iconRight }: BtnContentProps) => (
  <>
    {iconLeft}
    {content && <span>{content}</span>}
    {iconRight}
  </>
);

const AppButton = ({
  children,
  className,
  expanded = false,
  iconLeft,
  iconRight,
  label,
  shadow = false,
  tag = "button",
  theme = "is-default",
  ...rest
}: Props) => {
  // eslint-disable-next-line no-unused-vars
  const BTN_STYLES = classNames("btn", {
    "is-dark": theme === "is-dark",
    "is-primary": theme === "is-primary",
    "is-default": theme === "is-default",
    "is-transparent": theme === "is-transparent",
    "has-shadow": shadow,
    expanded: expanded,
  });

  const BTN_CLASS = classify(BTN_STYLES, className);

  const allProps = { ...rest, className: BTN_CLASS };

  const content = (
    <AppBtnContent
      content={children || label}
      iconLeft={iconLeft}
      iconRight={iconRight}
    />
  );

  switch (tag) {
    case "button":
      return <button {...(allProps as ButtonProps)}>{content}</button>;
    case "a":
      return <a {...(allProps as LinkProps)}>{content}</a>;

    default:
      return <button {...(allProps as ButtonProps)}>{content}</button>;
  }
};

export default AppButton;
