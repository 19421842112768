import AppRouter from "@components/AppRouter";
import { Route } from "@lib/types/router";
import Home from "./views/Search";
import OrderById from "./views/order/_id";

export const routes: Route[] = [
  {
    id: "DEFAULT_LAYOUT",
    path: "/",
    exact: true,
    component: AppRouter,
    routes: [
      {
        id: "HOME_VIEW",
        path: "/",
        component: Home,
      },
    ],
  },
  {
    id: "INTERNAL_LAYOUT",
    path: "/order",
    component: AppRouter,
    routes: [
      {
        id: "ORDER_VIEWV",
        path: "/order/:id",
        exact: true,
        component: OrderById,
      },
    ],
  },
];
