import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import AppDialog from "@components/ui/AppDialog";
import Swal from "@utils/swal";
import { maskCreditCardNumber, maskExpirationDate } from "@lib/mask";
import { ProductService } from "@core/services/product.service";
import AppInput from "@components/ui/AppInput";
import usePayment from "@hooks/use-payment";

type Props = {
  isVisible: boolean;
  onCancel?: () => void;
  onLoading?: (loading: boolean) => void;
  onCompleted?: (referenceCode: string) => void;
  paymentService: ProductService;
  reference: string;
  amount: number;
};

type FormData = {
  holder: string;
  email: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
};

const AppPayment = ({
  isVisible,
  onCancel = () => {},
  onLoading = () => {},
  onCompleted = () => {},
  paymentService,
  reference,
  amount,
}: Props) => {
  const { control, handleSubmit } = useForm<FormData>();

  const [paymentStatus, payOrder] = usePayment(reference, paymentService);

  useEffect(() => {
    const { loading, value } = paymentStatus;

    if (value) {
      onCompleted(value);
    }

    onLoading(loading);

    const err = paymentStatus.error as any;
    if (loading || !err) {
      return;
    }

    if (err.name && err.name === "PaymentError") {
      Swal.fire("Fallo durante el pago", err.message, "info");
    } else {
      Swal.fire(
        "Fallo durante el pago",
        "Ocurrio un fallo durante la operación",
        "info"
      );
    }
  }, [paymentStatus]);

  const onSubmit = async (data: FormData) => {
    await payOrder({
      holder: data.holder,
      email: data.email,
      cardNumber: data.cardNumber,
      expiration: data.expiration,
      cvv: data.cvv,
      amount,
    });
  };

  return (
    <AppDialog
      isVisible={isVisible}
      onCancel={() => onCancel()}
      title="Realizar pago"
      isForm
      cancelText="Cerrar"
      confirmText="Pagar"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4">
          <Controller
            name="holder"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <AppInput
                onChange={onChange}
                value={value}
                type="text"
                placeholder="Titular"
              />
            )}
          />
        </div>
        <div className="col-span-4">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <AppInput
                inputMode="email"
                onChange={onChange}
                value={value}
                type="email"
                placeholder="Correo electrónico"
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="cardNumber"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <AppInput
                inputMode="numeric"
                maxLength={19}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  maskCreditCardNumber(ev.target.value, (masked) => {
                    onChange(masked);
                  });
                }}
                value={value}
                type="text"
                placeholder="Número de tarjeta"
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="expiration"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <AppInput
                maxLength={5}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  maskExpirationDate(
                    ev.target.value,
                    { maxYear: 50 },
                    (masked) => {
                      onChange(masked);
                    }
                  );
                }}
                value={value}
                type="text"
                placeholder="Expiración"
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="cvv"
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <AppInput
                maxLength={3}
                onChange={onChange}
                value={value}
                type="text"
                placeholder="CCV"
              />
            )}
          />
        </div>
      </div>
    </AppDialog>
  );
};

export default AppPayment;
