import React, { useEffect, useState } from "react";
import { AsyncState } from "react-use/lib/useAsync";
import { useHistory } from "react-router-dom";
import { Context } from "@lib/types/ctx";
import Swal from "@utils/swal";
import AppSearch from "@components/elements/AppSearch";
import AppLoader from "@components/ui/AppLoader";
import { Order } from "@core/domain/order";
import HomeBackground from "@assets/img/home-background.jpg";
import FarmalealLogotype from "@assets/img/farmaleal-logotype.png";

type Props = {
  ctx: Context;
};

const Home = ({ ctx }: Props) => {
  const [state, setState] = useState<AsyncState<Order | null>>();
  const history = useHistory();

  useEffect(() => {
    if (state?.error && !state?.loading) {
      Swal.fire(
        "Orden no encontrada",
        "El número de referencia es incorrecto",
        "info"
      );
    } else if (!state?.loading && state?.value?.folio) {
      history.push(`/order/${state.value.folio}`);
    }
  }, [state]);

  return (
    <>
      <AppLoader isVisible={state?.loading} />

      <div className="flex h-screen items-stretch relative">
        <div className="bg-gray-900 bg-opacity-90 md:bg-opacity-100 md:bg-white absolute w-full inset-0 md:static md:w-1/2">
          <div className="container mx-auto px-4 py-24">
            <img
              className="mx-auto w-24"
              src={FarmalealLogotype}
              alt="Farmaleal Logotype"
            />
            <h2 className="text-xl font-bold text-white text-opacity-75 md:text-opacity-100 md:text-gray-500 text-center mt-6">
              Ingresa tu número de{" "}
              <span className="text-white md:text-primary-500">Referencia</span>
            </h2>

            <p className="text-center font-normal text-white text-opacity-75 md:text-opacity-100 md:text-gray-400 leading-relaxed mt-2">
              El número de referencia te permitirá localizar tu pedido para
              poder concretar el pago <span>😊</span>.
            </p>

            <AppSearch
              onFetch={(order) => setState(order)}
              productSrv={ctx.productSrv}
              className="max-w-xs mx-auto mt-8"
            />
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${HomeBackground})` }}
          className="bg-center bg-cover shadow-inner w-full md:w-1/2"
        />
      </div>
    </>
  );
};

export default Home;
