import React from "react";
import classNames from "classnames";
import { classify } from "@utils/classify";

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "ref"> {
  ref?:
    | string
    | React.RefObject<HTMLInputElement>
    | ((instance: HTMLInputElement | null) => void)
    | null
    | undefined;
  theme?: "is-danger";
}

// React.ClassAttributes<HTMLInputElement>.ref?: string | ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined

const AppInput = ({ className, ref, theme, ...props }: Props) => {
  const styles = classNames("input", {
    "is-danger": theme === "is-danger",
  });
  return <input ref={ref} className={classify(styles, className)} {...props} />;
};

export default AppInput;
