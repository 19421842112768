import React, { useEffect, useState } from "react";
import AppButton from "@components/ui/AppButton";
import numeral from "numeral";

type Costs = {
  delivery?: number;
  subtotal?: number;
};

type Props = {
  costs?: Costs;
  currency?: "MXN";
  onPay?: (amount: number) => void;
};

const AppOrderCost = ({ costs, currency = "MXN", onPay = () => {} }: Props) => {
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const t = (costs?.delivery || 0) + (costs?.subtotal || 0);
    setTotal(t);
  }, [costs]);
  return (
    <div className="bg-gray-900 p-8 rounded-md shadow space-y-3 mt-10">
      {costs?.delivery && costs.delivery > 0 && (
        <div className="flex justify-between">
          <div className="text-gray-300 text-sm">Costo de envio</div>
          <div className="text-gray-200 text-sm font-semibold">
            {numeral(costs.delivery).format("0,0.00")} <span>{currency}</span>
          </div>
        </div>
      )}

      {costs?.subtotal && costs.subtotal > 0 && (
        <div className="flex justify-between">
          <div className="text-gray-300 text-sm">Subtotal</div>
          <div className="text-gray-200 text-sm font-semibold">
            {numeral(costs.subtotal).format("0,0.00")} <span>{currency}</span>
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <div className="text-gray-300 text-sm">Total</div>
        <div className="text-white text-2xl font-semibold">
          {numeral(total).format("0,0.00")} <span>{currency}</span>
        </div>
      </div>
      <div className="pt-6">
        <AppButton
          theme="is-primary"
          label="Pagar"
          expanded
          onClick={() => onPay(total)}
        />
      </div>
    </div>
  );
};

export default AppOrderCost;
