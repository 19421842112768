import React, { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  exceptionComponent?: ReactNode;
  fallback?: ReactNode;
  hasError?: boolean;
  loading?: boolean;
};

const AppSuspense = ({
  children,
  exceptionComponent,
  fallback,
  hasError = false,
  loading = true,
}: Props) => {
  return (
    <>
      {loading && fallback}
      {hasError && exceptionComponent}
      {!loading && children}
    </>
  );
};

export default AppSuspense;
