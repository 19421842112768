import { ProductRepository } from "@repositories/product.repo";
import { ProductService } from "@core/services/product.service";
import { OpenPayPaymentMethod } from "../payments/openpay";

export const makeContext = () => {
  const productRp = new ProductRepository();
  const openPay = new OpenPayPaymentMethod({
    merchantId: String(process.env.REACT_APP_OPENPAY_ID),
    publicApiKey: String(process.env.REACT_APP_OPENPAY_PK),
    sandboxMode: Boolean(process.env.REACT_APP_SANDBOX_MODE),
  });

  const productSrv = new ProductService(productRp, openPay);

  return { productSrv };
};
