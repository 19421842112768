import { useAsyncFn } from "react-use";
import { CreditCard } from "@core/domain/card";
import { ProductService } from "@core/services/product.service";

const usePayment = (reference: string, productService: ProductService) => {
  const state = useAsyncFn(async (data: CreditCard) => {
    const referenceCode = await productService.payOrder(reference, {
      holder: data.holder,
      email: data.email,
      cardNumber: data.cardNumber,
      expiration: data.expiration,
      cvv: data.cvv,
      amount: data.amount,
    });
    return referenceCode;
  });

  return state;
};

export default usePayment;
