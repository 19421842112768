import { CreditCard } from "@core/domain/card";
import { PaymentMethod } from "@core/ports/payment";
import { api, ServerResponse } from "@utils/ky";

class PaymentError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "PaymentError";
  }
}

type ContructorOpenPay = {
  merchantId: string;
  publicApiKey: string;
  sandboxMode: boolean;
};

type OpenPayResponse = {
  data: {
    id: string;
    card: any;
  };
  status: number;
};

export class OpenPayPaymentMethod implements PaymentMethod {
  private OpenPay = (window as any).OpenPay;

  constructor(options: ContructorOpenPay) {
    this.OpenPay.setId(options.merchantId);
    this.OpenPay.setApiKey(options.publicApiKey);
    this.OpenPay.setSandboxMode(options.sandboxMode);
  }

  private clearCardNumber(cardNumber: string): string {
    const digits = cardNumber.split(" ").join("");
    return digits;
  }

  private clearCardExpiration(
    expiration: string
  ): { month: string; year: string } {
    const digits = expiration.split("/");
    return { month: digits[0], year: digits[1] };
  }

  private validateCard(creditCard: CreditCard) {
    const isValidNumber = this.OpenPay.card.validateCardNumber(
      creditCard.cardNumber
    );

    if (!isValidNumber) {
      throw new PaymentError("El número de tarjeta no es válido");
    }

    const isValidCVC = this.OpenPay.card.validateCVC(
      creditCard.cvv,
      creditCard.cardNumber
    );

    if (!isValidCVC) {
      throw new PaymentError("El código de seguridad no es válido");
    }

    const { month, year } = this.clearCardExpiration(creditCard.expiration);

    const isValidExpiry = this.OpenPay.card.validateExpiry(month, year);

    if (!isValidExpiry) {
      throw new PaymentError("El código de seguridad no es válido");
    }
  }

  private async generateToken(creditCard: CreditCard) {
    const { month, year } = this.clearCardExpiration(creditCard.expiration);
    return new Promise((resolve, reject) => {
      this.OpenPay.token.create(
        {
          card_number: creditCard.cardNumber,
          holder_name: creditCard.holder,
          expiration_year: year,
          expiration_month: month,
          cvv2: creditCard.cvv,
        },
        (res: OpenPayResponse) => {
          const SUCCESS_CORE = 200;
          if (res.status === SUCCESS_CORE && res?.data?.id) {
            resolve(res.data.id);
          } else {
            reject(new PaymentError("Bad Request"));
          }
        },
        () => {
          reject(new PaymentError("Internal Error"));
        }
      );
    });
  }

  async pay(reference: string, creditCard: CreditCard): Promise<string> {
    const cardNumber = this.clearCardNumber(creditCard.cardNumber);
    const { month, year } = this.clearCardExpiration(creditCard.expiration);

    this.validateCard(creditCard);

    const token = await this.generateToken({
      ...creditCard,
      cardNumber,
    });

    try {
      const response = await api.post(`Shop/Payment?folio=${reference}`, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          titular: creditCard.holder,
          mail: creditCard.email,
          deviceSessionId: token,
          amount: creditCard.amount,
          tarjeta: cardNumber,
          month: month,
          year: year,
          cvv: creditCard.cvv,
        }),
      });
      const data: ServerResponse = await response.json();
      const referenceCode = data?.data?.referencia as string | undefined;

      if (!referenceCode) {
        throw new Error();
      }
      return referenceCode;
    } catch (err) {
      throw new PaymentError(
        "El pago no pudo concretarse debido a un fallo interno. Por favor intentalo más tarde."
      );
    }
  }
}
