import msk from "msk";

export const clearDate = (date: string, options: { maxYear: number }) => {
  let cleanned = date;
  const MAX_YEAR_AVAILABLE = options.maxYear;
  const MAX_MONTH_AVAILABLE = 12;
  const month: number = Number(date.slice(0, 2));
  const year: number = Number(date.slice(3, 5));
  if (month > MAX_MONTH_AVAILABLE) {
    cleanned = String(MAX_MONTH_AVAILABLE);
  }

  if (date.length >= 3 && year > MAX_YEAR_AVAILABLE) {
    cleanned = `${cleanned.slice(0, 2)}/${MAX_YEAR_AVAILABLE}`;
  }

  return cleanned;
};

export const maskExpirationDate = (
  value: string,
  options: {
    maxYear: number;
  },
  callback: (masked: string) => void
) => {
  const CARD_LENGHT = 5;
  const EXPIRATION_MASK = "99/99";

  const newValue = clearDate(value, {
    maxYear: options.maxYear,
  });

  if (newValue.length <= CARD_LENGHT) {
    const masked = msk.fit(newValue, EXPIRATION_MASK);
    callback(masked);
  }
};

export const maskCreditCardNumber = (
  value: string,
  callback: (masked: string) => void
) => {
  const CARD_LENGHT = 19;
  const CARD_NUMBER_MASK = "9999 9999 9999 9999";
  if (value.length <= CARD_LENGHT) {
    const masked = msk.fit(value, CARD_NUMBER_MASK);
    callback(masked);
  }
};
