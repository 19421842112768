import React, { ReactNode } from "react";

type Props = {
  label?: string;
  children?: ReactNode;
};

const AppProductViewerProp = ({ label, children }: Props) => (
  <div className="space-y-1">
    <div className="font-semibold text-gray-300 text-xs uppercase tracking-widest">
      <span>{label}</span>
    </div>
    <div className="font-bold text-gray-500">
      <span>{children}</span>
    </div>
  </div>
);

export default AppProductViewerProp;
