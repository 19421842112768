import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { routes } from "./routes";
import AppRouter from "./components/AppRouter";

import { makeContext } from "./lib/context";

const App = () => (
  <Router>
    <AppRouter routes={routes} ctx={makeContext()}></AppRouter>
  </Router>
);

export default App;
