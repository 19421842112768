import React from "react";

const NotFound = () => (
  <div className="w-full h-screen bg-gray-900 flex items-center justify-center">
    <div className="container px-4 mx-auto">
      <h2 className="text-center text-white text-4xl">Not Found</h2>
    </div>
  </div>
);

export default NotFound;
