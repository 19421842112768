import React, { ReactNode } from "react";
import { useLockBodyScroll } from "react-use";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  children?: ReactNode;
  isVisible?: boolean;
};

const AppOverlay = ({ children, isVisible = false }: Props) => {
  useLockBodyScroll(isVisible);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-50 w-full h-screen bg-gray-900 bg-opacity-90 overflow-y-auto"
        >
          <div className="container mx-auto px-4 py-8 flex justify-center">
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AppOverlay;
