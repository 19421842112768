import React, { ReactNode, useRef } from "react";
import AppButton from "@components/ui/AppButton";
import AppOverlay from "@components/ui/AppOverlay";
import { motion, AnimatePresence } from "framer-motion";
import { useClickAway } from "react-use";

type CommonProps = {
  canCancel?: boolean;
  cancelText?: string;
  canConfirm?: boolean;
  children?: ReactNode;
  confirmText?: string;
  isForm?: boolean;
  onCancel?: () => void;
  onConform?: () => void;
  title?: string;
  isVisible?: boolean;
};

type FooterProps = Pick<
  CommonProps,
  | "canCancel"
  | "cancelText"
  | "canConfirm"
  | "confirmText"
  | "isForm"
  | "onCancel"
  | "onConform"
>;

type FormProps =
  | {
      isForm: true;
      onSubmit?:
        | ((ev: React.FormEvent<HTMLFormElement>) => void)
        | ((
            e?: React.BaseSyntheticEvent<object, any, any> | undefined
          ) => Promise<void>);
    }
  | {
      isForm?: false;
      onSubmit?: never;
    };

type Props = CommonProps & FormProps;

const AppDialogFooter = ({
  canCancel = true,
  cancelText = "Cancelar",
  canConfirm = true,
  confirmText = "Ok",
  isForm = false,
  onCancel = () => {},
  onConform = () => {},
}: FooterProps) => (
  <div className="p-3 md:px-6 bg-gray-100 flex justify-end">
    <div className="space-x-2">
      {canCancel && (
        <AppButton
          tag="button"
          theme="is-transparent"
          type="reset"
          onClick={() => onCancel()}
        >
          {cancelText}
        </AppButton>
      )}

      {canConfirm && (
        <AppButton
          tag="button"
          theme="is-primary"
          type={isForm ? "submit" : "button"}
          shadow
          onClick={isForm ? undefined : () => onConform()}
        >
          {confirmText}
        </AppButton>
      )}
    </div>
  </div>
);

const AppDialog = ({
  children,
  isForm = false,
  title,
  onSubmit,
  isVisible = false,
  ...rest
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    if (rest.onCancel) {
      rest.onCancel();
    }
  });

  return (
    <AppOverlay isVisible={isVisible}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 30 }}
          transition={{ delay: 0.4 }}
          ref={ref}
          className="w-full max-w-xl bg-white shadow rounded-md overflow-hidden"
        >
          {title !== undefined && (
            <div className="bg-gray-100 p-3 md:p-6">
              <h2 className="font-bold text-gray-500">{title}</h2>
            </div>
          )}

          {isForm && onSubmit ? (
            <form onSubmit={onSubmit}>
              <div className="bg-white p-3 py-6 md:px-6">{children}</div>
              <AppDialogFooter isForm {...rest} />
            </form>
          ) : (
            <div>
              <div className="bg-white p-3 py-6 md:px-6">{children}</div>
              <AppDialogFooter {...rest} />
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </AppOverlay>
  );
};

export default AppDialog;
