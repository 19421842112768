import React from "react";
import { Route } from "react-router-dom";
import { Route as iRoute } from "@lib/types/router";
import { Context } from "@lib/types/ctx";

interface Props extends iRoute {
  ctx: Context;
}

const AppRoute = ({
  path,
  component: Component,
  exact = false,
  routes,
  ctx,
}: Props) => (
  <Route
    path={path}
    exact={exact}
    render={(props) => <Component {...props} routes={routes} ctx={ctx} />}
  />
);
export default AppRoute;
