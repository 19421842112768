import React, { useEffect } from "react";
import { Box } from "react-feather";
import { Product } from "@core/domain/product";

type Props = {
  title?: string;
  products?: Product[];
  currency?: "MXN";
  className?: string;
  onReady?: (subtotal: number) => void;
  onClick?: (product: Product) => void;
};

const AppListOrder = ({
  title,
  products,
  currency = "MXN",
  className,
  onReady = () => {},
  onClick = () => {},
}: Props) => {
  useEffect(() => {
    if (products) {
      const query = (previous: number, current: Product) =>
        previous + current.precio * current.cantidad;
      const subtotal = Number(products.reduce(query, 0).toFixed(2));
      onReady(subtotal);
    }
  }, [products]);

  return (
    <section className={className}>
      {title && (
        <h3 className="uppercase tracking-widest text-xs font-semibold text-gray-400">
          {title}
        </h3>
      )}

      <div className="mt-6 space-y-6">
        {products !== undefined &&
          products.map((product, i) => (
            <article
              onClick={() => onClick(product)}
              role="button"
              tabIndex={0}
              key={i}
              className="flex border focus:outline-none appearance-none border-primary-100 border-opacity-50 items-center p-10 rounded-md shadow transform hover:scale-105 hover:shadow-xl transition duration-500 cursor-pointer"
            >
              <div className="flex-none text-gray-400">
                <Box />
              </div>
              <div className="flex-grow pl-6">
                <div className="font-bold text-gray-500">
                  {product.descripcion}
                </div>
                <div className="text-sm text-gray-300">
                  {product.sustanciaActiva}
                </div>
              </div>
              <div className="flex-none flex flex-col items-end font-medium">
                <div className="font-bold text-green">
                  {product.precio}{" "}
                  <span className="uppercase text-sm">{currency}</span>
                </div>
                <div className="font-medium text-gray-400">x2</div>
              </div>
            </article>
          ))}
      </div>
    </section>
  );
};

export default AppListOrder;
