import ky, { NormalizedOptions } from "ky";

class HttpError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "HttpError";
  }
}

class BadRequest extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "BadRequest";
  }
}

export type ServerResponse<T = any> = {
  statusCode: number;
  data: T;
};

export const api = ky.create({
  prefixUrl: String(process.env.REACT_APP_API_URL),
  hooks: {
    afterResponse: [
      async (req: Request, option: NormalizedOptions, res: Response) => {
        if (!res.ok) {
          throw new HttpError(
            "An error occurred while calling the http request"
          );
        }
        const data: ServerResponse = await res.json();
        const SUCCESS_CODE = 200;

        if (data.statusCode !== SUCCESS_CODE) {
          throw new BadRequest(
            "The server cannot process the request correctly"
          );
        }
      },
    ],
  },
});
