import React, { useEffect } from "react";
import { Search } from "react-feather";
import msk from "msk";
import { classify } from "@utils/classify";
import { ProductService } from "@core/services/product.service";
import useOrder from "@hooks/use-order";
import { Order } from "@core/domain/order";
import AppInput from "../ui/AppInput";
import AppButton from "../ui/AppButton";
import { AsyncState } from "react-use/lib/useAsync";

type Props = {
  className?: string;
  productSrv: ProductService;
  onFetch?: (order: AsyncState<Order | null>) => void;
};

const AppSearch = ({ className, productSrv, onFetch = () => {} }: Props) => {
  const { findOrderByReference, state, setReference, reference } = useOrder(
    productSrv
  );

  const INPUT_FORMAT = "SSSSSSSS-SSSS-SSSS-SSSS-SSSSSSSSSSSS";
  const INPUT_LENGTH = 36;

  const handleInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    if (value.length <= INPUT_LENGTH) {
      ev.target.value = msk(value, INPUT_FORMAT).toUpperCase();
      setReference(ev.target.value);
    }
  };

  const onSubmitHandler = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    await findOrderByReference();
  };

  useEffect(() => {
    onFetch(state);
  }, [state]);

  return (
    <form autoComplete="off" onSubmit={(ev) => onSubmitHandler(ev)}>
      <div className={classify("flex w-full space-x-2", className)}>
        <AppInput
          type="search"
          placeholder="Referencia"
          className="bg-white md:bg-gray-100"
          value={reference}
          onChange={(ev) => handleInput(ev)}
        />
        <AppButton
          tag="button"
          iconLeft={<Search />}
          disabled={reference.length !== INPUT_LENGTH}
          type="submit"
          theme={
            reference.length === INPUT_LENGTH ? "is-primary" : "is-default"
          }
        >
          Buscar
        </AppButton>
      </div>
    </form>
  );
};

export default AppSearch;
