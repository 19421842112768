import React, { useEffect, useState } from "react";
import { ShoppingBag } from "react-feather";
import { useParams, useHistory } from "react-router-dom";
import OrderHero from "@assets/img/order-hero.jpg";
import HandIllustration from "@assets/img/hand.png";
import AppButton from "@components/ui/AppButton";
import AppListOrder from "@components/elements/AppListOrder";
import { Context } from "@lib/types/ctx";
import UseOrder from "@hooks/use-order";
import NotFound from "@views/NotFound";
import AppLoader from "@components/ui/AppLoader";
import AppOrderCost from "@components/elements/AppOrderCost";
import AppSuspense from "@components/AppSuspense";
import AppPayment from "@components/elements/AppPayment";
import AppProductViewer from "@components/elements/AppProductViewer";
import { Product } from "@core/domain/product";
import Swal from "@utils/swal";

type Props = {
  ctx: Context;
};

const OrderById = ({ ctx }: Props) => {
  const {
    setReference,
    reference,
    order,
    findOrderByReference,
    state,
  } = UseOrder(ctx.productSrv);
  const { id } = useParams<{ id: string }>();

  const [subtotal, setSubtotal] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    setReference(id);
  }, [id]);

  useEffect(() => {
    if (!order) {
      findOrderByReference();
    }
  }, [reference]);

  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [productViewer, setProductViewer] = useState<boolean>(false);
  const [onPayment, setPaymentLoader] = useState<boolean>(false);
  const [folio, setFolio] = useState<string>("");

  const [focusedProduct, setFocusedProduct] = useState<Product>();

  useEffect(() => {
    if (folio) {
      Swal.fire({
        title: "Pago realizado exitosamente",
        html: (
          <p>
            Tu número de referencia es <b>{folio}</b>
          </p>
        ),
        icon: "success",
      }).then(() => {
        history.push("/");
      });
    }
  }, [folio]);

  return (
    <>
      <AppLoader isVisible={onPayment} />
      <AppProductViewer
        product={focusedProduct}
        isVisible={productViewer}
        onClose={() => setProductViewer(false)}
      />

      <AppPayment
        paymentService={ctx.productSrv}
        reference={id}
        amount={amount}
        onCompleted={(folio) => {
          setFolio(folio);
        }}
        onLoading={(loading) => {
          if (loading) {
            setPaymentModal(false);
          }

          setPaymentLoader(loading);
        }}
        isVisible={paymentModal}
        onCancel={() => setPaymentModal(false)}
      />

      <AppSuspense
        loading={state.loading}
        hasError={Boolean(state.error)}
        fallback={<AppLoader isVisible />}
        exceptionComponent={<NotFound />}
      >
        <div className="container mx-auto px-4">
          <div
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(87, 74, 226, 0.86), rgba(87, 74, 226, 0.86)), url(${OrderHero})`,
            }}
            className="w-full rounded-md bg-center bg-cover shadow-inner mt-12 inline-flex p-8"
          >
            <div className="hidden md:flex justify-center items-center flex-none">
              <img
                className="w-40"
                src={HandIllustration}
                alt="Hand Illustration"
              />
            </div>
            <div className="flex flex-col flex-grow justify-center">
              <div className="text-center md:text-left">
                <h2 className="font-bold text-white text-2xl">Farmaleal</h2>
                <p className="text-white text-opacity-75 max-w-2xl mt-2">
                  En nuestra farmacia podrás encontrar el precio más competitivo
                  del mercado, programar las entregas de tus medicamentos, envío
                  gratis y grandes beneficios
                </p>

                <AppButton
                  className="mt-6"
                  tag="a"
                  href="https://www.farmaleal.com.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                  theme="is-dark"
                  iconLeft={<ShoppingBag />}
                  label="Visitar tienda"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-3 gap-8 py-8">
            <AppListOrder
              onClick={(product) => {
                setFocusedProduct(product);
                setProductViewer(true);
              }}
              onReady={(sub) => setSubtotal(sub)}
              className="xl:col-span-2"
              title="Detalles de Orden"
              currency="MXN"
              products={order?.producto}
            />
            <div>
              <AppOrderCost
                costs={{ subtotal }}
                onPay={(amount) => {
                  setAmount(amount);
                  setPaymentModal(true);
                }}
              />
            </div>
          </div>
        </div>
      </AppSuspense>
    </>
  );
};

export default OrderById;
