import { ProductRepository as ProductRp } from "@core/ports/repositories/product.port";
import { Order } from "@core/domain/order";
import { api, ServerResponse } from "@utils/ky";

export class ProductRepository implements ProductRp {
  async get(reference: string): Promise<Order> {
    const res = await api.get(`Shop/ShopOrder?folio=${reference}`);
    const data: ServerResponse<Order> = await res.json();
    return data.data;
  }
}
