import { Dispatch, useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import { ProductService } from "@core/services/product.service";
import { State, AvailableActions } from "@store";

const useOrder = (service: ProductService) => {
  const [reference, setReference] = useState<string>("");
  const order = useSelector((state: State) => state.order);
  const dispatch = useDispatch<Dispatch<AvailableActions>>();

  const [state, findOrderByReference] = useAsyncFn(async () => {
    if (reference) {
      const order = await service.get(reference);
      return order;
    }
    return undefined;
  }, [reference]);

  useEffect(() => {
    if (!state.error && state.value) {
      dispatch({ type: "SET_ORDER", order: state.value });
    }
  }, [state]);

  return {
    order,
    findOrderByReference,
    setReference,
    reference,
    state,
  };
};

export default useOrder;
