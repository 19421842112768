import { ProductService as Service } from "../ports/services/product.port";
import { ProductRepository as Repository } from "../ports/repositories/product.port";
import { Order } from "../domain/order";
import { PaymentMethod } from "@core/ports/payment";
import { CreditCard } from "@core/domain/card";

export class ProductService implements Service {
  private repo: Repository;
  private paymentMethod: PaymentMethod;

  constructor(repo: Repository, paymentMethod: PaymentMethod) {
    this.repo = repo;
    this.paymentMethod = paymentMethod;
  }

  async get(reference: string): Promise<Order> {
    const order = await this.repo.get(reference);
    return order;
  }

  async payOrder(reference: string, cardData: CreditCard) {
    const referenceCode = await this.paymentMethod.pay(reference, cardData);
    return referenceCode;
  }
}
