import React from "react";
import "@assets/css/loader.css";

type Props = {
  isVisible?: boolean;
};

const AppLoader = ({ isVisible = false }: Props) => (
  <>
    {isVisible && (
      <div className="fixed z-50 w-full h-screen bg-gray-900 bg-opacity-90 flex justify-center items-center">
        <div className="container mx-auto px-4 py-8 flex justify-center">
          <span className="loader">
            <span className="loader-inner" />
          </span>
        </div>
      </div>
    )}
  </>
);

export default AppLoader;
