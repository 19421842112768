import React from "react";
import AppDialog from "@components/ui/AppDialog";
import { Product } from "@core/domain/product";
import AppProductViewerProp from "./AppProductViewerProp";

type Props = {
  product?: Product;
  isVisible?: boolean;
  onClose?: () => void;
};

const AppProductViewer = ({
  isVisible = false,
  product,
  onClose = () => {},
}: Props) => {
  return (
    <AppDialog
      isVisible={isVisible}
      onCancel={() => onClose()}
      cancelText="Cerrar"
      canConfirm={false}
    >
      <div>
        <h3 className="font-bold text-xl text-gray-500">
          {product?.descripcion}
        </h3>
        <div className="bg-red w-6 h-1 mt-2 rounded-full" />

        <div className="space-x-3">
          <span className="bg-gray-100 p-2 rounded-lg inline-block mt-3 text-xs tracking-widest font-bold text-gray-500 uppercase">
            {product?.sustanciaActiva}
          </span>
        </div>

        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6">
          <AppProductViewerProp label="Cantidad">
            {product?.cantidad} unidades
          </AppProductViewerProp>

          <AppProductViewerProp label="Copago">
            {product?.copago}
          </AppProductViewerProp>

          <AppProductViewerProp label="Deducible">
            {product?.deducible}
          </AppProductViewerProp>

          <AppProductViewerProp label="EAN">
            {product?.ean}
          </AppProductViewerProp>

          <AppProductViewerProp label="Precio">
            {product?.precio}
          </AppProductViewerProp>

          <AppProductViewerProp label="Sustancia Activa">
            {product?.sustanciaActiva}
          </AppProductViewerProp>

          <AppProductViewerProp label="Fecha de entrega">
            {product?.tiempoEntrega}
          </AppProductViewerProp>
        </div>
      </div>
    </AppDialog>
  );
};

export default AppProductViewer;
