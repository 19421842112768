import React from "react";
import { Switch, Route } from "react-router-dom";
import { Route as iRoute } from "@lib/types/router";
import { Context } from "@lib/types/ctx";
import AppRoute from "./AppRoute";
import NotFound from "@views/NotFound";

type Props = {
  routes: iRoute[];
  ctx: Context;
};

function AppRouter({ routes, ctx }: Props) {
  return (
    <Switch>
      {routes.map((route) => (
        <AppRoute key={route.id} {...route} ctx={ctx} />
      ))}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

export default AppRouter;
